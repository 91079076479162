import { useEffect, useState } from 'react'
import { Link, NavLink, useParams} from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import UserService from '../../services/UserService'

function NetworkNav ({ i18n, t, title }) {
    const { userId } = useParams()
    const { gatewayId } = useParams()
    const [user, setUser] = useState(null)

    useEffect(() => {
        UserService.getUser(userId).then((resource) => {
            setUser(resource)
        }).catch((error) => {
            console.log(error)
        })
    }, [userId])

	return (
        <>
          <h2>
		    <Link to={'/users'}><box-icon name='arrow-back'></box-icon></Link>
            { title }&nbsp;
          </h2>
		  <div className='user-nav'>
		    <NavLink to={'/users/' + userId + '/objects/network/' + gatewayId + '/actions' }>{t('users.nav.actions')}</NavLink>
            {/*<NavLink to={'/users/' + userId + '/objects/network/' + gatewayId + '/analyse'}>{t('users.nav.analyze')}</NavLink>*/}
		  </div>
        </>
	)
}

export default withTranslation('ui')(NetworkNav)
